<template>
  <div>
<!--    <div v-if="jurisdictionList.indexOf('admin-operation-sms') !== -1">-->
<!--      <div class="first" v-if="showFirst">-->
<!--        <div class="all-list">-->
<!--          <div class="label-new">短信服务商:</div>-->
<!--          <RadioGroup v-model="animal" @on-change="changeAnimal">-->
<!--            <Radio label="4">阿里云</Radio>-->
<!--            <Radio label="5">腾讯云</Radio>-->
<!--          </RadioGroup>-->
<!--        </div>-->
<!--        <div class="all-list">-->
<!--          <div class="label-new">SignName:</div>-->
<!--          <Input v-model="signArr.sign" placeholder="请输入空间域名SignName" style="width: 350px" />-->
<!--        </div>-->
<!--        <div class="all-list">-->
<!--          <div class="label-new">TemplateCode:</div>-->
<!--          <Input v-model="signArr.template_id" placeholder="请输入TemplateCode" style="width: 350px" />-->
<!--        </div>-->
<!--        <div class="all-list">-->
<!--          <div class="label-new">Key:</div>-->
<!--          <Input v-model="signArr.key" placeholder="请输入Key" style="width: 350px" />-->
<!--        </div>-->
<!--        <div class="all-list">-->
<!--          <div class="label-new">secret:</div>-->
<!--          <Input v-model="signArr.secret" placeholder="请输入secretKey" style="width: 350px" />-->
<!--        </div>-->
<!--        <div class="all-list">-->
<!--          <div class="label-new">应用id:</div>-->
<!--          <Input v-model="signArr.sdk_appid" placeholder="请输入应用id" style="width: 350px" />-->
<!--        </div>-->
<!--        <div class="bottom-btn">-->
<!--          <Button type="primary" style="margin-right: 30px" @click="signKeep">保存</Button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="jurisdictionList.indexOf('admin-operation-cloud') !== -1">
      <div class="title-msg">云存储配置
        <i-switch size="large" v-model="config.show_status" style="margin-left: 20px" @on-change="changeSecond" >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </div>
      <div class="first" v-if="config.status == 1">
        <div class="all-list">
          <div class="label-new">云服务商:</div>
          <RadioGroup v-model="typeRadio" @on-change="changeConfigFirst">
            <Radio label="1">阿里云</Radio>
            <Radio label="2">腾讯云</Radio>            
            <Radio label="3">七牛云</Radio>
          </RadioGroup>
        </div>
        <div v-if="typeRadio == 2">
          <div class="all-list">
            <div class="label-new">空间域名Domain:</div>
            <Input v-model="config.tengxun_uploads.domain" placeholder="请输入空间域名Domain" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">accessKey:</div>
            <Input v-model="config.tengxun_uploads.access_key" placeholder="请输入accessKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">secretKey:</div>
            <Input v-model="config.tengxun_uploads.secret_key" placeholder="请输入secretKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">存储空间名称:</div>
            <Input v-model="config.tengxun_uploads.storage_title" placeholder="请输入存储空间名称" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">所属地域:</div>
            <Input v-model="config.tengxun_uploads.area" placeholder="请输入所属地域" style="width: 350px" />
          </div>
          <div class="bottom-btn">
            <Button type="primary" style="margin-right: 30px" @click="configKeepTengxun">保存</Button>
          </div>
        </div>
        <div v-if="typeRadio == 1">
          <div class="all-list">
            <div class="label-new">空间域名Domain:</div>
            <Input v-model="config.aliyun_uploads.domain" placeholder="请输入空间域名Domain" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">accessKey:</div>
            <Input v-model="config.aliyun_uploads.access_key" placeholder="请输入accessKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">secretKey:</div>
            <Input v-model="config.aliyun_uploads.secret_key" placeholder="请输入secretKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">存储空间名称:</div>
            <Input v-model="config.aliyun_uploads.storage_title" placeholder="请输入存储空间名称" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">所属地域:</div>
            <Input v-model="config.aliyun_uploads.area" placeholder="请输入所属地域" style="width: 350px" />
          </div>
          <div class="bottom-btn">
            <Button type="primary" style="margin-right: 30px" @click="configKeepAliyun">保存</Button>
          </div>
        </div>
        <div v-if="typeRadio == 3">
          <div class="all-list">
            <div class="label-new">空间域名Domain:</div>
            <Input v-model="config.qiniu_uplaods.domain" placeholder="请输入空间域名Domain" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">accessKey:</div>
            <Input v-model="config.qiniu_uplaods.access_key" placeholder="请输入accessKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">secretKey:</div>
            <Input v-model="config.qiniu_uplaods.secret_key" placeholder="请输入secretKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">存储空间名称:</div>
            <Input v-model="config.qiniu_uplaods.storage_title" placeholder="请输入存储空间名称" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">所属地域:</div>
            <Input v-model="config.qiniu_uplaods.area" placeholder="请输入所属地域" style="width: 350px" />
          </div>
          <div class="bottom-btn">
            <Button type="primary" style="margin-right: 30px" @click="configKeepQiniu">保存</Button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="jurisdictionList.indexOf('admin-operation-oem') !== -1">
      <div class="title-msg">OEM配置
        <i-switch size="large" v-model="oemList.show_status" style="margin-left: 20px" @on-change="changeThird" >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </div>
      <div class="first"  v-if="oemList.status == 1">
        <div class="all-list">
          <div class="label-new">站点logo:</div>
         <div v-if="!oemList.site_logo">
           <Upload
               :format="['jpg','jpeg','png','gif']"
               :on-format-error="handleFormatError"
               :on-success="uploadImgSuccess"
               :on-progress="handleProgress"
               :show-upload-list="false"
               :headers="headers"
               :action="uploadImg">
             <div class="uplads_box">
               <div class="center">
                 <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                 <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
               </div>
             </div>
           </Upload>
         </div>
          <div class="logo-pic" v-else>
            <div class="clear-pic"  @click="clearLogo"><Icon type="ios-close-circle-outline" size="30" /></div>
            <img :src="oemList.site_logo">
          </div>
        </div>
        <div class="all-list">
          <div class="label-new">登录页背景图:</div>
         <div v-if="!oemList.login_bg">
           <Upload
               :format="['jpg','jpeg','png','gif']"
               :on-format-error="handleFormatError"
               :on-success="uploadImgSuccessBg"
               :on-progress="handleProgress"
               :show-upload-list="false"
               :headers="headers"
               :action="uploadImg">
             <div class="uplads_box">
               <div class="center">
                 <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                 <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
               </div>
             </div>
           </Upload>
         </div>
          <div class="logo-pic" v-else>
            <div class="clear-pic" @click="clearBg"><Icon type="ios-close-circle-outline" size="30" /></div>
            <img :src="oemList.login_bg">
          </div>
        </div>
        <div class="all-list">
          <div class="label-new">网站标题:</div>
          <Input v-model="oemList.site_title" placeholder="请输入网站标题" style="width: 350px" />
        </div>
        <div class="all-list">
          <div class="label-new">底部版权信息:</div>
          <Input v-model="oemList.floor_cr" placeholder="请输入底部版权信息" style="width: 350px" />
        </div>
        <div class="bottom-btn">
          <Button type="primary" style="margin-right: 30px" @click="submitOem">保存</Button>
        </div>
      </div>
    </div>
    <div style="width: 500px;">
      <div class="title-msg">短信配置
        <i-switch size="large"  v-model="signArr.show_status"  style="margin-left: 20px" @on-change="change" >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
        <div v-if="signArr.status == 1">
          <Input style="margin-top: 20px" v-model="signArr.sign" type="textarea" :autosize="{minRows: 2,maxRows: 5}"
                 placeholder="请输入短信签名"></Input>
          <Button style="margin-top: 10px" type="primary" @click="autograph">提交签名</Button>
        </div>
      </div>
    </div>
    <div style="width: 500px;">
      <div class="title-msg">短信通道
        <i-switch size="large"  v-model="passageway.passageway_status"  style="margin-left: 20px" @on-change="changePassageway" >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
        <span style="font-size:12px">（开启状态是腾讯云短信 关闭状态是凌凯短信）</span>        
      </div>
    </div>
  </div>
</template>
<script>
import {configAdd, configInfo,aliSmsAdd,sms_channel_switch} from '@/api/operation'
import config from '@/config/index'
import {getCookies} from '@/utils/helper'
import {ormAdd} from "../../api/operation";
export default {
  data () {
    return {
      uploadImg:config.apiUrl+ '/adminapi/config/fileUpload',
      headers:{
        'authori-zation':getCookies('token')
      },
      passageway:{
        status:0,
        passageway_status:false
      },
      showModel:false,
      synopsis:'',
      formItem:{
        input:''
      },
      showFirst:false,
      showSecond:false,
      showThird:false,
      animal:'4',
      value:'',
      typeRadio:'1',
      config:{
        status:0,
        show_status:false,
        aliyun_uploads:{
          domain:'',
          access_key:'',
          secret_key:'',
          storage_title:'',
          area:'',
        },
        qiniu_uplaods:{
          domain:'',
          access_key:'',
          secret_key:'',
          storage_title:'',
          area:'',
        },
        tengxun_uploads:{
          domain:'',
          access_key:'',
          secret_key:'',
          storage_title:'',
          area:'',
        },
      },
      logoImage:'',
      bgImage:'',
      signArr:{
        status:0,
        show_status:false,
        sign:''
      },
      oemList:{
        site_title:'',
        status:0,
        show_status:false,
        floor_cr:'',
        type:'6',
        site_logo:'',
        login_bg:''
      },
      jurisdictionList:[],
      total_config:{}
    }
  },
  created() {
    this.getConfig()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted(){
  },
  methods: {
    sms_channel_switch(){
      sms_channel_switch({status: this.passageway.status}).then(res=>{
        this.$Message.success(res.msg)
        this.getConfig()
      }).catch(res=>{
        this.$Message.error(res.msg)
        this.getConfig()
      })
    },
    autograph(){
      aliSmsAdd({
        type:'7',
        sign:this.signArr.sign,
        status:this.signArr.status
      }).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    changeConfigFirst(e) {
      this.typeRadio = e;
    },
    getConfig(){
      configInfo().then(res=>{
        this.oemList = res.data.orm_set
        this.oemList.show_status = res.data.orm_set.status == 1 ? true : false
        this.signArr = res.data.sign_sms
        this.signArr.show_status = res.data.sign_sms.status == 1 ? true : false
        this.total_config = res.data.upload_set
        this.config.show_status = this.total_config.status == 1 ? true : false
        if(this.config.show_status){
          this.config.status = 1
        }
        this.config.aliyun_uploads = this.total_config.aliyun_uploads
        this.config.qiniu_uplaods = this.total_config.qiniu_uplaods
        this.config.tengxun_uploads = this.total_config.tengxun_uploads
        this.passageway.status = res.data.sms_channel.status
        this.passageway.passageway_status = res.data.sms_channel.status == 1 ? true : false
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeep(){
      configAdd({
        type:this.typeRadio,
        status:this.config.status,
        domain:this.config.domain,
        access_key:this.config.access_key,
        secret_key:this.config.secret_key,
        storage_title:this.config.storage_title,
        area:this.config.area,}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeepQiniu(){
      configAdd({
        type:this.typeRadio,
        status:this.config.status,
        domain:this.config.qiniu_uplaods.domain,
        access_key:this.config.qiniu_uplaods.access_key,
        secret_key:this.config.qiniu_uplaods.secret_key,
        storage_title:this.config.qiniu_uplaods.storage_title,
        area:this.config.qiniu_uplaods.area,}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeepTengxun(){
      configAdd({
        type:this.typeRadio,
        status:this.config.status,
        domain:this.config.tengxun_uploads.domain,
        access_key:this.config.tengxun_uploads.access_key,
        secret_key:this.config.tengxun_uploads.secret_key,
        storage_title:this.config.tengxun_uploads.storage_title,
        area:this.config.tengxun_uploads.area,}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeepAliyun(){
      configAdd({
        type:this.typeRadio,
        status:this.config.status,
        domain:this.config.aliyun_uploads.domain,
        access_key:this.config.aliyun_uploads.access_key,
        secret_key:this.config.aliyun_uploads.secret_key,
        storage_title:this.config.aliyun_uploads.storage_title,
        area:this.config.aliyun_uploads.area,}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    change(e){
      this.signArr.status = e ? 1 : 0;
      this.signArr.show_status = e;
      this.autograph();
      // if (!this.signArr.status) {
      //
      // }
    },
    changePassageway (e){
      this.passageway.status = e ? 1 : 0;
      this.signArr.passageway_status = e;
      this.sms_channel_switch();
    },
    changeSecond(e){
      this.config.status = e ? 1 : 0;
      this.config.show_status = e;
      console.log(!this.config.status)
      if(this.typeRadio == 1){
        this.configKeepAliyun()
      }else if (this.typeRadio == 2){
        this.configKeepTengxun()
      }else {
        this.configKeepQiniu()
      }
      // if (!this.config.status) {
      //   // this.configKeep();
      // }
    },
    changeThird(e){
      this.oemList.status = e ? 1 : 0;
      this.oemList.show_status = e;
      this.submitOem();
      // if (!this.oemList.status) {
      //
      // }
    },
    handleSuccess(){

    },
    //文件上传类型错误
    handleFormatError () {
      this.$Message.warning('暂不支持上传此格式');
    },
    //添加图片上传图片成功
    uploadImgSuccess (e) {
      this.oemList.site_logo = e.data
      this.$Message.destroy()
      this.$Message.success(e.msg);
    },
    uploadImgSuccessBg(e){
      this.oemList.login_bg = e.data
      this.$Message.destroy()
    },
    // 文件上传时
    handleProgress(){
      const msg = this.$Message.loading({
        content: '上传中...',
        duration: 0
      });
      setTimeout(msg, 3000);
    },
    clearLogo(){
      this.oemList.site_logo = ''
    },
    clearBg(){
      this.oemList.login_bg = ''
    },
    submitOem(){
      this.oemList.type = '6'
      ormAdd(this.oemList).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
  }
}
</script>
<style scoped>
.label-new {
  margin-right: 10px;
  width: 120px;
}
.logo-pic {
  width: 105px;
  height: 105px;
  position: relative;
}
.logo-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.clear-pic {
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 9999;
  color: #A0A4BD;
  cursor: pointer;
}
.uplads_box{
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px dashed  #A0A4BD;
  background: #F7F8FA;
  border-radius: 5px;
  cursor: pointer;
}
.title-msg {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}
.all-list {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.bottom-btn {
  margin-left: 180px;
}
</style>
